import axios from "axios";

// Crear la instancia de axios específica para la landing page
const Http = axios.create({
  baseURL: process.env.VUE_APP_HTTP_API,
  headers: {
    "Content-type": "application/json",
  }
});

// Almacenamiento del token
let landingPageToken = null;
let isRefreshing = false;
let failedQueue = [];

// Procesar la cola de peticiones fallidas
const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

// Función para obtener el token de landing page
// Aquí las credenciales están encapsuladas y no expuestas directamente
const getLandingPageToken = async () => {
  try {
    console.log("Solicitando token para landing page...");
    
    // Estas credenciales pueden estar en un archivo .env separado específico para la landing page
    const LANDING_PAGE_USERNAME = process.env.VUE_APP_HTTP_USER;
    const LANDING_PAGE_PASSWORD = process.env.VUE_APP_HTTP_PASS;
    
    const response = await axios.post(`${process.env.VUE_APP_HTTP_API}/Authenticate`, {
      "username": LANDING_PAGE_USERNAME,
      "password": LANDING_PAGE_PASSWORD
    });
    
    console.log("Token obtenido:", response.data ? "Sí" : "No");
    
    if (typeof response.data === 'object' && response.data.token) {
      return response.data.token;
    } else if (typeof response.data === 'object' && response.data.access_token) {
      return response.data.access_token;
    } else if (typeof response.data === 'string') {
      return response.data;
    } else {
      console.error("Formato de token inesperado:", response.data);
      return response.data;
    }
  } catch (error) {
    console.error("Error obteniendo token de landing page:", error);
    throw error;
  }
};

// Interceptor de solicitud
Http.interceptors.request.use(async (config) => {
  // No añadir token a la petición de autenticación
  if (config.url === '/Authenticate') {
    return config;
  }
  
  if (!landingPageToken) {
    try {
      landingPageToken = await getLandingPageToken();
    } catch (error) {
      return Promise.reject(error);
    }
  }
  
  config.headers.Authorization = `Bearer ${landingPageToken}`;
  
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Interceptor de respuesta
Http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
        .then(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return Http(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
      }
      
      originalRequest._retry = true;
      isRefreshing = true;
      
      try {
        console.log("Refrescando token debido a 401...");
        landingPageToken = null;
        const newToken = await getLandingPageToken();
        landingPageToken = newToken;
        
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        
        processQueue(null, newToken);
        
        return Http(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }
    
    return Promise.reject(error);
  }
);

// Exportar la instancia http configurada para la landing page
export default Http;